import React from 'react';
import { createIcon } from '../createIcon';
import { G, Path } from '../nbSvg';

export const SunIcon = createIcon({
  viewBox: '0 0 20 20',
  path: (
    <G>
      <Path d="M9.99958 5.21094C7.36096 5.21094 5.22266 7.34924 5.22266 9.98786C5.22266 12.6265 7.36096 14.7648 9.99958 14.7648C12.6382 14.7648 14.7765 12.6265 14.7765 9.98786C14.7765 7.34924 12.6382 5.21094 9.99958 5.21094ZM9.99958 12.7715C8.46198 12.7715 7.21437 11.5255 7.21437 9.98627C7.21437 8.44867 8.46198 7.20265 9.99958 7.20265C11.5356 7.20265 12.7832 8.44867 12.7832 9.98627C12.7832 11.5255 11.5356 12.7715 9.99958 12.7715Z" />
      <Path d="M11.1236 0.465264C11.1236 0.208732 10.9149 0 10.6599 0H9.34222C9.08568 0 8.87695 0.208732 8.87695 0.465264V3.48789C8.87695 3.74283 9.08568 3.95315 9.34222 3.95315H10.6599C10.9149 3.95315 11.1236 3.74283 11.1236 3.48789V0.465264Z" />
      <Path d="M4.05371 2.46121C3.87206 2.28116 3.5757 2.28116 3.39565 2.46121L2.46512 3.39174C2.28507 3.57338 2.28507 3.86975 2.46512 4.0498L4.60183 6.18651C4.78347 6.36816 5.07984 6.36816 5.25989 6.18651L6.19042 5.25598C6.37206 5.07434 6.37206 4.77797 6.19042 4.59792L4.05371 2.46121Z" />
      <Path d="M0.465264 8.875C0.341998 8.87542 0.2239 8.92457 0.136737 9.01174C0.0495736 9.0989 0.000419992 9.217 0 9.34026V10.658C0 10.9145 0.210325 11.1232 0.466858 11.1232H3.48789C3.74442 11.1232 3.95475 10.9145 3.95475 10.658V9.34186C3.95475 9.08692 3.74602 8.87659 3.48948 8.87659H0.465264V8.875Z" />
      <Path d="M2.46316 15.9472C2.28311 16.1288 2.28311 16.4252 2.46316 16.6052L3.39369 17.5358C3.57374 17.7158 3.87011 17.7158 4.05175 17.5358L6.18847 15.3975C6.37011 15.2158 6.37011 14.9194 6.18847 14.7394L5.25794 13.8089C5.07629 13.6288 4.77993 13.6288 4.59987 13.8089L2.46316 15.9472Z" />
      <Path d="M8.87695 19.5344C8.87695 19.7909 9.08568 19.9997 9.34381 19.9997H10.6599C10.9149 19.9997 11.1236 19.7893 11.1236 19.5344V16.5102C11.1236 16.2552 10.9149 16.0449 10.6583 16.0449L9.34222 16.0465C9.08568 16.0465 8.87695 16.2552 8.87695 16.5118V19.5344Z" />
      <Path d="M15.9484 17.5342C16.13 17.7142 16.4264 17.7142 16.6064 17.5342L17.537 16.6036C17.6242 16.5164 17.6732 16.398 17.6732 16.2746C17.6732 16.1512 17.6242 16.0329 17.537 15.9456L15.3987 13.8089C15.2186 13.6288 14.9222 13.6288 14.7406 13.8089L13.8101 14.7394C13.6284 14.921 13.6284 15.2174 13.8101 15.3975L15.9484 17.5342Z" />
      <Path d="M19.5348 11.1236C19.658 11.1232 19.7761 11.074 19.8633 10.9869C19.9505 10.8997 19.9996 10.7816 20 10.6583V9.34222C19.9996 9.21895 19.9505 9.10085 19.8633 9.01369C19.7761 8.92653 19.658 8.87737 19.5348 8.87695H16.5121C16.3889 8.87737 16.2708 8.92653 16.1836 9.01369C16.0964 9.10085 16.0473 9.21895 16.0469 9.34222V10.6599C16.0469 10.9149 16.2556 11.1252 16.5121 11.1252L19.5348 11.1236Z" />
      <Path d="M17.5358 4.05175C17.7174 3.87011 17.7174 3.57374 17.5358 3.39369L16.6036 2.46316C16.4236 2.28311 16.1256 2.28311 15.9472 2.46316L13.8089 4.60147C13.6288 4.78152 13.6288 5.07789 13.8089 5.25953L14.7394 6.19006C14.921 6.3717 15.2174 6.3717 15.3975 6.19006L17.5358 4.05175Z" />
    </G>
  ),
});
